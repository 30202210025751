@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crete+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: '20px',
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: x-large;
  color: #000000;
  letter-spacing: 2px;
}

.brand-name {
  font-family: "Lobster", cursive;
  font-size: 30px;
  letter-spacing: 2px;
}

/* .hero-section {
  z-index: 100;
} */

.hero-text {
  font-family: "Crete Round", serif;
}

.navbar {
  position: fixed;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 100;
}

.navbar {
  font-family: "Roboto", sans-serif;
  font-weight: 540;
}

/* navbar menu toogle sm */
@media only screen and (max-width: 600px) {
  .show {
    background-color: rgb(0, 0, 0);
    color: #fff;
    padding: 0px;
  }
}

a:link {
  text-decoration: none;
}

/* 
.hero-section {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45)),
    url("pictures/cover.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  .hero-section {
    min-height: 70vh;
  }
}

@media only screen and (max-width: 600px) {
  .hero-section {
    min-height: 60vh;
  }
} */

.hero-video {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45));
  height: 70vh;
}

.fullscreen-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
  height: 70vh;
}

.hero-pic {
  margin-top: 20%;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
    text-align: center;
  }
}


.featured_card_container {
  background-color: #fff;
  width: -webkit-fill-available;
  max-width: 400px;
  border-radius: 5px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #00000040;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -webkit-fill-available;
  pointer-events: auto;
  background-color: rgb(0, 0, 0);
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.share .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.comment_section .modal-content {
  margin-top: 70px;
}

.location_modal {
  height: calc(100vh - 60px)
}

.location_modal .modal-content {
  margin-top: 70px;
}

.share .modal-content {
  display: flex;
  justify-content: space-between;
  border-radius: 50vh;
  position: absolute;
  bottom: 120px;
  left: calc(50vw - 135px);
  height: min-content;
  width: min-content
}

.share .modal-dialog {
  height: 100vh;
  margin: 0
}

.featured_card_grid_container {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

.featured-images {
  min-height: 200px;
  max-height: 200px;
}

.section-header {
  margin: 10px 0 10px 0;
  padding: 0;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}

.host-btn {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.become-host {
  background-color: rgb(36, 31, 48);
}

.become-host-card-header {
  background-color: #ccc;
  font-size: large;
}

.done-img {
  max-width: 500px;
}

.h4 {
  color: #012970;
}

@media only screen and (max-width: 600px) {
  .done-img {
    max-width: 250px;
  }

  .successful-post-text {
    margin-top: 50px;
  }
}

.profile-thumb {
  min-height: 30vh;
  max-height: 30vh;
}

.card-header-create-profile {
  background-color: #ccc;
  min-height: 50px;
  padding: 10px;
}

.profile-image-div {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}

@media only screen and (min-width: 600px) {
  .dropdown-menu-bar {
    margin-left: 100px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  background-size: 100%;
}

.my-listings-thumbnail {
  min-height: 250px;
  max-height: 250px;
}

.create-profile-button {
  margin-left: 50%;
}

/* spinner */
.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.home_loading {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
  z-index: 9999999999999;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  border-radius: 50%;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

/* end of spinner */
.caraousel-slider {
  padding-bottom: 10px;
  padding-top: 0px;
}

.d-block {
  max-height: 500px;
}

.card-booking-form-header {
  background-color: rgb(46, 44, 44);
  color: #fff;
  font-weight: 600;
}

.heading-p {
  color: #012970;
}

.category-cards {
  height: 145px;
  width: 200px;
  box-shadow: 0px 0px 9px #00000054;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 18px;
}

.category-img {
  width: 200px;
  height: 125px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body {
  padding: 10px;
}

.find-roommates-content {
  background-color: #ccc;
  padding: 20px;
}

.find-roommates-content-cards {
  min-height: 450px;
}

.find-roommates-content-cards-pic {
  max-height: 250px;
}

.footer {
  background-color: #000;
  padding: 20px;
  color: #fff;
}

.all-properties {
  margin-top: 100px;
}

.auth-btn {
  margin-top: 200px;
}

.mr-top {
  margin-top: 200px;
}

.mr-top-2 {
  margin-top: 20px;
}

/* lg */


/* sm */

/*input file*/
.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  position: relative;
  border-radius: 3px;
}

.file-input>[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.form-control-file {
  padding: '0'
}

.file-input>.button {
  display: inline-block;
  cursor: pointer;
  background: rgb(119, 0, 255);
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
}

.file-input:hover>.button {
  background: rgb(40, 136, 231);
  color: white;
}

.file-input>.label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen>.label {
  opacity: 1;
}

.fa-times-circle {
  color: rgb(228, 22, 22);
}

.fa-check-square {
  color: #15c56d;
}

/* Vertical center */
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

/*End of vertical center */

/* bottom nav */
.bottom_nav_container {
  height: 60px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #000;
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 0;
}

.topbar_container {
  width: 100vw;
  height: 60px;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  align-items: center;
  z-index: 999999999;
  box-shadow: 0px 0px 7px #282828;
}

/* bottom nav end */

/* main scroller */
.home_container {
  width: 100vw;
  overflow: scroll;
  height: 100%;
  max-height: calc(100vh - 60px);
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.main-scroll-view-child {
  height: calc(100vh - 60px);
  width: 100%;
  scroll-snap-align: start;
}

/* main scroller end */


.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px 10px 0 0;
  float: right;
  display: flex;
}

.map_popup_btn {
  margin-top: 10px;
}

.geocoder-pin-right {
  display: none;
}

.mapboxgl-ctrl-geolocate {
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin: 8px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E");
}

.mapContainer button,
input {
  overflow: visible;
  border: none;
  height: 45px;
  padding-left: 10px;
  border-radius: 10px;
}


.mapContainer button,
input:focus {
  overflow: visible;
  border: none;
  outline: none;
}


/* explore */
.explore_container {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 60px);
  width: 100vw;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 60px;
  overflow: scroll;
}

.window_container {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 59px);
  width: 100vw;
  padding: 30px;
  overflow: scroll;
}

.small_map {
  width: -webkit-fill-available;
  height: 170px;
  box-shadow: 0px 5px 10px #00000054;
  border-radius: 10px;
}

.small_map .mapboxgl-map {
  border-radius: 10px;
}

.mapboxgl-ctrl-logo {
  visibility: hidden;
}

.mapboxgl-ctrl-bottom-right {
  visibility: hidden;

}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px 10px 0 0;
  float: right;
  display: flex;
  width: min-content;
  background-color: #fff0;

  margin-right: 0px;
}

.fullScreen {
  width: min-content;
  height: min-content;
  padding: 5px;
  margin: 10px;
}

.three h4 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 12px;
  position: relative;
}

.three h4:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #111;
  border-radius: 3px;
}